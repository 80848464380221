@import '_variables.scss';

.menu .main-menu {
  width: 121px;
}

// When lengthy headings are rendered (Chargeable Department), due to insufficient width, the "Commissioning Tool" text goes to the next line
.navbar .navbar-left {
  flex-basis: 32%;
}

// The title was broken and wrapped to the next line as the previously-set width for this area was not enough
nav.navbar.fixed-top .navbar-left {
  flex-basis: 40%;
}

// Removed the original filter style to render the CW logo in #ffffff
// Increased loaded logo size
.fixed-top div.navbar-left .logo-img-sm {
  filter: none !important;
  width: 50px !important;
}

.modal.show .modal-right {
  // overflow-y: hidden;
}
.modal-right .modal-content {
  height: calc(100% - 64px);
  min-height: auto;
  top: 64px;
  position: sticky;
}
.modal-right .modal-content .modal-body {
  overflow-y: auto;
}
.modal-right .modal-content .modal-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 1rem;
}
.modal-right .modal-header {
  flex-direction: column;
}
