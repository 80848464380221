// Primary Colors
$primary-color: #e4002b;
$primary-color-2: #686b6b;
$primary-color-3: #ffffff;

// Secondary Colors
$secondary-color-2: #a7a8aa;

$accent-color-1: #eeeded;
$accent-color-2: #b5bd00;

$cw185: #e4002b;
$cw187: #a6192e;
$cw629: #98d3dd;
$cw632: #0093b2;
$cw390: #b5bd00;
$cw165: #ff671f;
$cw2955: #003865;
$cw424: #686b6b;
$cwCG6: #a7a8aa;
$white: #ffffff;
$black: #000000;
$darkText: #333333;
$yellow: #fff3c6;
$purple: #5c36d6;
$Cultured: #f6f6f6;

$space-color: #ff671f;
$unit-color: #e4002b;
$building-color: #0093b2;
$plot-color: #b5bd00;

$approved-color: #00bd48;
$rejected-color: #e4002b;
$merged-color: #ffc70c;
$pending-color: #959595;
$criticlefield-color: #5c36d6;
$navalt-color: #37474f;
$subtitle-underline-color: #e3e3e3;

// Request Status Badge Background Color
$request-draft-bg-color: #f4f4f4;
$request-submitted-bg-color: $merged-color;
$request-awaiting-bg-color: $merged-color;
$request-approved-bg-color: $approved-color;
$request-rejected-bg-color: #d5d5d5;
$request-aborted-bg-color: #d5d5d5;
$request-completed-bg-color: $approved-color;
