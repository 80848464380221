.full-width-stretch {
  width: 100%;
}

.visibility-hidden {
  visibility: hidden;
}

.fix-wrap {
  word-break: break-word;
  word-wrap: anywhere;
}

@media all and (max-width: 1800px) {
  .responsive-margin-top {
    margin-top: 3rem !important;
  }
}
