p-dropdown,
.p-dropdown.p-component {
  width: 100%;
}

.p-dropdown .p-dropdown-label {
  color: #0093b2;
}

.home-drop-down-wrapper .ng-select,
.home-drop-down-wrapper .ng-select .p-placeholder {
  border: 0;
  background-color: transparent;
  color: #0093b2;
}
.p-dropdown-trigger-icon {
  color: #0093b2;
}

.ng-select .p-dropdown-label {
  color: #0093b2;
}

.top-drop-downs {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media all and (max-width: 1070px) {
  .top-drop-downs {
    justify-content: flex-start;
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 18px;
    flex-wrap: wrap;
  }
}

@media all and (max-width: 1080px) {
  .top-drop-downs {
    justify-content: flex-start;
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 18px;
  }
}

.home-drop-down-wrapper {
  width: auto !important;
  margin-right: 2% !important;
}

.home-drop-down-wrapper:last-child {
  margin-right: 0 !important;
}

.home-drop-down-wrapper .ng-select {
  border: 0;
  background-color: transparent;
  color: #0093b2;
}

app-client-selector {
  margin-right: 16px;
  float: right;
  border: 0;
  background-color: transparent;
  color: #0093b2;

  * {
    font-size: 1rem;
    cursor: pointer;
  }

  .ng-select {
    .ng-select-container {
      border-color: transparent !important;
      background-color: transparent !important;
      color: #0093b2 !important;
      border-radius: 4px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      .ng-arrow-wrapper {
        margin-left: 4px;
      }
      .ng-value-container {
        margin-top: 5px;
        .ng-value {
          color: #0093b2;
        }
      }
    }

    .ng-dropdown-panel {
      border-color: #e4002b !important;
      border-top: 0;
      border-radius: 4px;
      overflow: hidden;
    }
    .ng-input {
      font-size: 1rem;
    }

    .ng-arrow-wrapper .ng-arrow {
      border-color: #0093b2 transparent transparent;
      top: 1px;
      ::before {
        content: '\e902';
      }
    }
  }
}

.ng-select {
  .ng-select-container {
    .ng-value-container {
      .ng-value {
        white-space: break-spaces !important;
        word-break: break-word;
      }
    }
  }
}

.ng-select.ng-select-disabled {
  .ng-select-container {
    background-color: #e9ecef !important;
    .ng-arrow-wrapper:hover {
      .ng-arrow {
        border-top-color: #d7d7d7 !important;
      }
    }
  }
}
