.modal {
  .modal-dialog {
    .modal-content {
      .modal-footer {
        padding: 1rem;
      }
    }
  }
}

.modal-right .modal-content {
  margin-top: 64px;

  app-request-list-filter {
    height: 100%;

    &.app-request-list-filter {
      input {
        padding: 0.2rem 0.75rem;
      }

      .ng-select-container {
        min-height: 25px;
        padding: 0;
      }

      .modal-body {
        margin-top: 30px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.modal-dialog.modal-lg {
  max-width: 1500px;
  width: 100%;
  background-color: red;
}

modal-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;

  .modal-dialog {
    width: 100%;
    max-width: 600px;
  }

  .modal-confirm.modal-dialog {
    box-shadow: 0 0 120px 570px #33333370;

    .modal-header {
      * {
        font-size: 1rem;
      }
      padding: 1.25rem 1.75rem;
    }
    .modal-body {
      font-size: 1rem;

      p {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
}

.toast-container .ngx-toastr {
  box-shadow: 0 0 12px #606060;
}

.about-info-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .modal-header {
    position: relative;
    height: 120px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #cb0c1e;
    background-image: url('../..//assets/images/about-header-bg.jpg');
    background-size: 780px 120px;
    background-position: right center;
    background-repeat: no-repeat;
    color: #ffffff;
    font-weight: normal;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* max-width: 780px; */

    h1 {
      font-weight: 400;
      margin: 0;
      padding: 0;
      text-shadow: 0 0 8px #333333d6;
    }

    .close {
      top: 15px;
    }
  }

  .modal-body {
    display: flex;
    background-color: #fff;
    flex-direction: column;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .modal-footer {
    background-color: #fff;
  }
}
