.accordion-group {
  margin-bottom: 2px;
  > .panel {
    background-color: $Cultured;
    transition: 0.1s;
    &:hover {
      background-color: $accent-color-1;
    }
    .panel-heading {
      transition: 0.1s;
      padding: 1rem 1.75rem;
      .panel-title {
        .accordion-toggle {
          i.fa {
            width: 10px;
          }
        }
      }
    }
  }

  &.panel-open {
    .panel {
      &:hover {
        background-color: inherit;
      }
      .panel-heading {
        border-top: 2px solid $primary-color;
        .panel-title {
          // color: $primary-color-3;
          .accordion-toggle {
            i.fa {
              // color: $primary-color-3;
            }
          }
        }
      }
    }
  }
}
