@import './text_input';
@import './buttons';
@import './drop_down';

a {
  cursor: pointer;
  transition: color 0.2s;
  &:hover {
    color: $primary-color !important;
  }
}
