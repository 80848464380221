#system-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 10px;
  background: rgba(255, 255, 255, 0.78);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  backdrop-filter: blur(1000px);
  box-shadow: 0 -4px 32px 4px #3333330f;
  p,
  a {
    margin: 0;
    font-size: 11px;
  }
  .footer-links {
    display: flex;
    align-items: center;
    button {
      padding: 0.2rem 0.7rem;
      cursor: pointer;
    }
  }
}
