.document-wrapper {
  max-width: 75%;
  padding: 3em 4em;
  .document-page-footer {
    margin-top: 2em;
    margin-bottom: 2em;
    .document-page-footer-row {
      display: flex;
      align-items: center;
      .document-page-footer-cell {
        span {
          font-size: 16px;
          color: $secondary-color-2;
        }
      }
    }
  }
}
