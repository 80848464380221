@import './modals';
@import './accordion';
@import './table';
@import './document';
@import './system_footer';

#app-container {
  position: relative;
  min-height: 100vh;

  main {
    padding-top: 80px;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 40px;
  }

  app-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.border {
  &-right-none {
    border-right: 0 !important;
  }
}
