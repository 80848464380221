.table {
  color: $primary-color-2;

  $table-border: $primary-color-2 solid 2px;
  $table-cell-border: $primary-color-2 solid 1px;

  .table-main-heading {
    margin-bottom: 3em;
    .table-main-heading-logo,
    .table-main-heading-text {
      display: flex;
      align-items: end;
    }

    .table-main-heading-logo {
      justify-content: flex-start;
      img {
        max-width: 100%;
      }
    }

    .table-main-heading-text {
      justify-content: flex-end;

      h1,
      h2 {
        color: $black;
      }

      h1 {
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 40px;
        font-weight: 800;
      }
    }
  }

  .table-grid {
    border-top: $table-border;
    border-bottom: $table-border;
    font-size: 17px;
    margin-bottom: 2em;
  }
  .table-row {
    margin-right: 0px;
    margin-left: 0px;

    .table-row-header-cell {
      font-weight: 800;
      color: $black;
      background-color: lighten($secondary-color-2, 20%);
    }

    .table-row-header-cell,
    .table-row-data-cell {
      color: $black;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    div:not(:last-child) {
      border-right: $table-cell-border;
    }

    &:not(:first-child) {
      border-top: $table-cell-border;
    }
  }
}
