.close {
  width: 30px;
  height: 30px;
  padding: 0 !important;
  left: calc(100% - 30px);
  position: absolute;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  z-index: 99999;
  display: flex;
  align-content: center;
  justify-content: center;
  top: 0;
  background-color: #e4002b !important;
  opacity: 1;
  color: #fff !important;

  &:hover {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
