input.form-control {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

textarea.form-control {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.ng-select.ng-select-single .ng-select-container {
  height: 30px;
  min-height: 30px;
}

.form-group label {
  margin-bottom: 0.2rem;
}
