@import '_variables.scss';
@import '_helper.scss';
@import '_corrections.scss';
@import '_colors.scss';
@import '_typography.scss';
@import '_icons.scss';
@import './ui_layouts/all_ui_layouts';
@import './ui_inputs/all_ui_inputs';
@import '../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@media (max-width: 576px) {
  .mobile-btn-xs {
    padding: 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
  }
}

.form-group {
  label {
    color: var(--theme-color-3);
  }
}

.avatars-overlap-container {
  display: inline-flex;
  flex-direction: row-reverse;

  .avatar-overlap {
    position: relative;
    border: 2px solid #fff;
    border-radius: 100%;
    overflow: hidden;
    background-color: #fff;
  }

  .avatar-overlap:not(:last-child) {
    margin-left: -55px;
  }
}

quill-editor {
  display: block;

  .ql-editor > * {
    font-size: 0.8rem;
  }
}

.list-no-indent {
  list-style-type: none;
  padding-inline-start: initial;
}

//top nav and side nav changes

nav.navbar.fixed-top {
  background-color: #37474f;

  .user .tooltip-inner {
    max-width: 700px;
    text-align: left;
    white-space: nowrap;
  }
}

div.main-menu {
  background-color: #37474f !important;
}

div.main-menu a.link-hover-no-color {
  border: none !important;
}

div.main-menu a.link-hover-no-color i {
  font-size: 2rem;
}

.login-control-btn {
  color: #b0bec5 !important;
}

a.link-hover-no-color {
  color: #b0bec5 !important;
}

a.link-hover-no-color i {
  color: #b0bec5 !important;
}

.menu .main-menu ul li.active a.link-hover-no-color {
  color: #37474f !important;
  font-weight: 700 !important;
  background-color: white !important;
}

.menu .main-menu ul li.active a.link-hover-no-color i {
  color: #37474f !important;
  // font-weight: 700 !important;
}

.menu .main-menu ul li.active:after {
  background: white !important;
}

.menu .sub-menu ul li.active span {
  color: #e4002b !important;
  font-weight: 700 !important;
}

div.sub-menu {
  // background-color: #37474f !important;
  height: calc(100% - 3rem) !important;
  border-left: 1px solid grey !important;
}

div.bread-crumb-outer {
  position: relative;
  height: 100%;
}

// div.navbar-left div.search {
//   margin-left:20px !important;
//   background: none !important;
//   width: 150px !important;
// }

// div.navbar-left div.search .search-icon {
//   right: auto !important;
// }

// div.navbar-left div.search input {
//   padding: .4rem 1rem .4rem 2.2rem !important;
//   transition: .5s;
// }

// div.navbar-left div.search input:focus {
//   border-bottom: 1px red solid;
// }

.search-box-outer {
  position: relative;
  height: 100%;
}

.navbar-left .search {
  margin-left: 20px;
  width: 145px;
  position: absolute;
  top: 15px;
}

.search-show-btn {
  padding-left: 20px;
  font-weight: 500;
  font-size: 1.1rem;
  color: #b0bec5 !important;
  cursor: pointer;
  top: 23px !important;
  position: absolute;
}

.app-menu {
  top: 60px !important;
  height: calc(100% - 3rem) !important;
}

div.bread-crumb-outer .gap {
  height: 100%;
  width: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #e4002b;
}

div.bread-crumb {
  background-color: white;
  height: 100%;
  -webkit-transform: skewX(-30deg);
  -moz-transform: skewX(-30deg);
  -ms-transform: skewX(-30deg);
  -o-transform: skewX(-30deg);
  transform: skewX(-30deg);
  max-width: fit-content !important;
}

div.bread-crumb label {
  -webkit-transform: skewX(30deg);
  -moz-transform: skewX(30deg);
  -ms-transform: skewX(30deg);
  -o-transform: skewX(30deg);
  transform: skewX(30deg);
  // text-transform: uppercase;
  margin-top: 15px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 1.1rem;
  font-weight: 600;
  position: relative;
  top: 0.3rem;
}

div.bread-crumb label .line {
  background-color: #e4002b;
  width: 60px;
  height: 3px;
}

nav.navbar.fixed-top {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 4rem;
}

nav.navbar.fixed-top .navbar-left {
  height: 100% !important;
}

nav.navbar.fixed-top .navbar-left a.commission-logo {
  height: 100% !important;
  background-color: #e4002b;
  padding-top: 7px;
}

nav.navbar.fixed-top .navbar-left a.menu-button {
  height: 100% !important;
  background-color: #e4002b;
  padding-top: 22px;
}

nav.navbar.fixed-top .navbar-left a.menu-button i.main {
  color: white !important;
  font-size: 1.2rem !important;
}

div.menu {
  padding-top: 3rem !important;
}

div.main-menu {
  height: calc(100% - 3rem) !important;
}

div.app-menu.filter-ani {
  top: 4rem !important;
  height: calc(100% - 4rem) !important;
}

div.navbar-left .logo-img-sm {
  margin-top: 5px !important;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(232deg) brightness(103%) contrast(101%);
  width: 33px !important;
}

div.navbar-left a label.logo-text {
  font-size: 1.1rem;
  padding-left: 8px;
  position: relative;
  top: 5px;
  color: white;
  padding-right: 20px;
}

.p-top {
  padding-top: 8px;
}

.breadCrumbTitle {
  white-space: nowrap;
}

@media (max-width: 1515px) {
  .breadCrumbTitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 1385px) {
  .commission-logo .logo-text {
    display: none;
  }
}

@media (max-width: 770px) {
  .d-flex.align-items-center.navbar-left {
    background-color: #e4002b;
  }
  div.navbar-left .logo-img-sm {
    margin-right: 10px;
    margin-left: 20px !important;
  }

  .bread-crumb.navbar-left {
    position: relative;
    left: 26px;
  }

  .breadCrumbTitle {
    font-size: 1rem;
  }

  div.bread-crumb label {
    margin-left: 18px;
    margin-right: 12px;
  }

  .fal.fa-search.search-show-btn {
    padding-left: 50px;
  }

  .logStatusLbl {
    display: none;
  }

  .fas.fa-lock.fas.fa-sign-out-alt {
    font-size: 1.4rem;
  }

  .fas.fa-sign-in-alt {
    font-size: 1.4rem;
  }
}

@media (max-width: 400px) {
  .mr-4.login-control-btn {
    margin-right: 0.5rem !important;
  }
  .fal.fa-search.search-show-btn {
    padding-left: 45px;
  }
}

@media (max-width: 320px) {
  .bread-crumb.navbar-left .line {
    position: relative;
    top: 9px;
  }
  .breadCrumbTitle {
    font-size: 0.7rem;
    position: relative;
    bottom: 5px;
  }

  .menu-button-mobile {
    margin-left: 5px;
  }
}

/* You can add global styles to this file, and also import other style files */

.align-input .col-md-6.order-md-last {
  display: flex;
  flex-direction: column;
}

.align-input .col-md-6.order-md-last input.form-control {
  margin-top: auto;
}

.align-input .col-md-6.order-md-first {
  display: flex;
  flex-direction: column;
}

.align-input .col-md-6.order-md-first input.form-control {
  margin-top: auto;
}

.p-autocomplete-input {
  width: 100%;
}

.ng-select {
  width: 100%;
}

.p-autocomplete {
  width: 100%;
}

.wide-modal-lg {
  max-width: 550px !important;
}

input:disabled::-webkit-input-placeholder {
  /* WebKit browsers */
  opacity: 0;
}

.progressbar-text {
  color: #ffffff;
  font-size: 0.6rem;
}

.card .lead {
  font-size: 1.4rem;
  line-height: 26px;
  word-break: break-word;
  margin-bottom: 2px;
  float: left;
  display: block;
}

.d-block.mb-3 {
  height: 150px;
}

.card-body .card-label {
  display: inline-block;
  width: 100%;
}

.intensity-cell {
  align-items: first baseline;
  margin-bottom: 10px;
}

.intensity-unit-label {
  margin-left: 2px;
}

.card-body .fa-angle-right {
  color: #dd0505;
  font-weight: normal;
}
.card-body .fa-angle-down {
  color: #dd0505;
  font-weight: normal;
}

.property-name-value {
  padding-left: 10px;
  font-weight: 100;
}

.grid-column-label {
  text-align: left;
}

.property-name-grid-column-label {
  padding-left: 37px;
}

.right-aligned-grid-column-label {
  text-align: right;
  white-space: nowrap;
}

.right-aligned-value {
  font-weight: 100;
  text-align: right;
  white-space: nowrap;
}

.property-manager-value {
  font-weight: 100;
}

.grid-header-search-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.view-in-powerbi-btn {
  order: 2;
  width: auto !important;
}

.grid-record-row {
  padding-bottom: 12px;
}

.section-heading {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

.section-heading::after {
  width: 60%;
  height: 1px;
  background-color: #e4002b;
}

.p-multiselect {
  font-size: 0.76rem !important;
}

.p-multiselect .p-multiselect-label {
  padding: 0.1rem 0.3rem;
}

.p-multiselect-trigger-icon {
  color: #939393;
  font-size: 0.76rem !important;
}

.p-multiselect-header {
  display: none !important;
}

.heading-underline::after {
  width: 60px;
  height: 1.5px;
  display: block;
  content: '';
  background-color: #dd0505;
}

.home-drop-down-wrapper {
  width: auto !important;
  margin-right: 2% !important;
}

.home-drop-down-wrapper:last-child {
  margin-right: 0 !important;
}

.home-drop-down-wrapper .p-dropdown {
  border: 0;
  background-color: transparent;
  color: #0093b2;
}

.p-dropdown-trigger-icon {
  color: #0093b2;
}

.ng-select .p-dropdown-label {
  color: #0093b2;
}

.apexcharts-svg .apexcharts-legend {
  // left: -12% !important;
  width: auto;
  display: flex !important;
  flex-direction: row;
  justify-content: left !important;
  padding-left: 0;
  padding-right: 0;
}

app-dashboard-multi-column-chart.inner-dashboard-chart apex-chart > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 0 !important;
  position: relative;
}
.inner-dashboard-chart .card-body {
  padding: 0.75rem;
}

app-dashboard-multi-column-chart.inner-dashboard-chart apx-chart > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-full-height-200 {
  overflow-y: scroll;
  height: calc(100vh - 250px);
}

.modal-full-height-250 {
  overflow-y: scroll;
  height: calc(100vh - 195px);
}

.btn {
  white-space: nowrap;
}

.text-new {
  color: #004b9b !important;
}

.exclamation-color {
  color: #f89406;
}

.badge-new {
  color: white;
  background-color: #004b9b;
}

.accent-light {
  border-left: 5px solid #909090;
}

.accent-info {
  border-left: 5px solid #3195a5;
}

.accent-warning {
  border-left: 5px solid #b69329;
  color: #b69329;
}

.accent-secondary {
  border-left: 5px solid #e4002b;
  color: #e4002b;
}

.accent-success {
  border-left: 5px solid #3e884f;
}

.accent-dark {
  border-left: 5px solid #131113;
}

.accent-new {
  border-left: 5px solid #004b9b;
}

.no-link-mode {
  cursor: default;
  color: inherit;
}
.link-mode {
  cursor: pointer;
  color: inherit;
}

.link-mode-color {
  cursor: pointer;
}

body {
  padding-bottom: 0px;
}

.card-main-heading {
  color: #686b6b;
  font-weight: 600;
}
.hidepdf {
  overflow: hidden;
  height: 0;
}
